import React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import ContentfulRichTech from "../components/ContentfulRichText";

import { HomeIcon } from "@heroicons/react/solid";

import {
  LightningBoltIcon,
  MailIcon,
  ScaleIcon,
} from "@heroicons/react/outline";
import People from "../assets/icons/People.svg";

import { CheckIcon, ThumbUpIcon, UserIcon } from "@heroicons/react/solid";
import Newsletter from "../components/Newsletter";
import SEO from "../components/SEO";

const timeline = [
  {
    id: 1,
    content:
      "Understanding people is understanding business. We believe through self-awareness we can have a growth mindset and communicate effectively",
    target: "Self-Awareness",
    href: "#",
    date: "Sep 20",
    datetime: "2020-09-20",
    icon: UserIcon,
    iconBackground: "bg-e-blue",
  },
  {
    id: 2,
    content:
      "Understanding and anticipating issues so we focus on needs and not answering questions",
    target: "Being Helpful",
    href: "#",
    date: "Sep 22",
    datetime: "2020-09-22",
    icon: ThumbUpIcon,
    iconBackground: "bg-e-dark-blue",
  },
  {
    id: 3,
    content:
      "Always asking what we could have done better or differently to grow and be accountable",
    target: "Own It!",
    href: "#",
    date: "Sep 28",
    datetime: "2020-09-28",
    icon: CheckIcon,
    iconBackground: "bg-e-yellow",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// const features = [
//   {
//     name: "Competitive rates",
//     description:
//       "Consequuntur omnis dicta cumque, inventore atque ab dolores aspernatur tempora ab doloremque.",
//     icon: People,
//   },
//   {
//     name: "No hidden fees",
//     description:
//       "Corporis quisquam nostrum nulla veniam recusandae temporibus aperiam officia incidunt at distinctio ratione.",
//     icon: ScaleIcon,
//   },
//   {
//     name: "Instant transfers",
//     description:
//       "Omnis, illo delectus? Libero, possimus nulla nemo tenetur adipisci repellat dolore eligendi velit doloribus mollitia.",
//     icon: LightningBoltIcon,
//   },
//   {
//     name: "Reminder emails",
//     description:
//       "Veniam necessitatibus reiciendis fugit explicabo dolorem nihil et omnis assumenda odit? Quisquam unde accusantium.",
//     icon: MailIcon,
//   },
// ];

const SingleService = ({ service }) => {
  return (
    <Layout>
      <SEO
        title={service.title}
        slug={service.title
          .toLowerCase()
          .replaceAll(" ", "")
          .replaceAll("'", "-")
          .replaceAll(":", "-")}
      />
      {/* HERO */}
      <div className="hidden lg:block relative bg-e-blue overflow-hidden h-[350px]">
        <div className="absolute inset-0">
          <GatsbyImage
            className="w-full h-full object-cover"
            image={service.image.gatsbyImageData}
            alt= {service.title}
          />
          {/* <div
            className="absolute inset-0 bg-e-blue mix-blend-multiply"
            aria-hidden="true"
          /> */}
          <svg
            width="925"
            height="366"
            viewBox="0 0 925 366"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute right-0 top-0"
          >
            <path
              d="M344.5 0.327271L0 365.327H925V0.327271H344.5Z"
              fill="#1D71B8"
              fillOpacity="0.4"
            />
          </svg>
        </div>
        <div className="relative max-w-7xl mx-auto pt-[310px] px-4 flex justify-end items-end">
          <nav className="flex" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-4">
              <li>
                <div>
                  <Link
                    to="/"
                    className="text-white hover:text-e-blue transition"
                  >
                    <HomeIcon
                      className="flex-shrink-0 h-6 w-6"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Home</span>
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <AnchorLink
                    to="/#services"
                    className="ml-4 text-xl font-medium text-white hover:text-e-blue transition"
                  >
                    Services
                  </AnchorLink>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <Link
                    to="#"
                    className="ml-4 text-xl font-medium text-white hover:text-e-blue transition"
                  >
                    {service.title}
                  </Link>
                </div>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="bg-gray-50 overflow-hidden pt-24 lg:pb-24">
        <div className="relative sm:max-w-7xl max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <svg
            className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)"
            />
          </svg>

          <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
            <div className="lg:col-span-1">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-500 sm:text-4xl pb-4">
                {service.title}
              </h2>
              <p className="text-gray-400 pb-4">
                {service.shortDescription.shortDescription}
              </p>
              <Link
                to="/#contact"
                className="text-e-blue hover:underline flex items-center"
              >
                Get in touch with us
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </Link>
              <div className=" p-8 mx-auto max-w-[500px] bg-e-dark-blue mt-12 lg:mt-24">
                <h2 className="text-e-yellow text-2xl font-medium pb-2">
                  Summary
                </h2>
                <div className="text-white">
                  <ContentfulRichTech richText={service.summary} />
                </div>
              </div>
            </div>
            <dl className="hidden xl:grid mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
              <Link
                to="/people-management"
                className="transition cursor-pointer hover:bg-gray-100 p-4"
              >
                <dt>
                  <div className="flex items-center justify-center h-12 w-12 text-white">
                    <svg
                      width="60"
                      height="60"
                      viewBox="0 0 60 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="60" height="60" rx="8" fill="#1D71B8" />
                      <mask
                        id="mask0_1879_1942"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="60"
                        height="60"
                      >
                        <rect width="60" height="60" rx="8" fill="#5C5E84" />
                      </mask>
                      <g mask="url(#mask0_1879_1942)">
                        <rect
                          x="-31.2002"
                          y="32.3999"
                          width="60"
                          height="60"
                          rx="8"
                          fill="#FCD980"
                        />
                        <rect
                          x="9.59961"
                          y="-10.8"
                          width="60"
                          height="60"
                          rx="8"
                          fill="#083974"
                        />
                      </g>
                      <path
                        d="M34 20.8749C37.003 20.8749 39.4374 18.4405 39.4374 15.4375C39.4374 12.4344 37.003 10 34 10C30.9969 10 28.5625 12.4344 28.5625 15.4375C28.5625 18.4405 30.9969 20.8749 34 20.8749Z"
                        fill="#FCD980"
                      />
                      <path
                        d="M45 20.8749C46.8984 20.8749 48.4374 19.3359 48.4374 17.4375C48.4374 15.539 46.8984 14 45 14C43.1015 14 41.5625 15.539 41.5625 17.4375C41.5625 19.3359 43.1015 20.8749 45 20.8749Z"
                        fill="#FCD980"
                      />
                      <path
                        d="M23 20.8749C24.8984 20.8749 26.4374 19.3359 26.4374 17.4375C26.4374 15.539 24.8984 14 23 14C21.1015 14 19.5625 15.539 19.5625 17.4375C19.5625 19.3359 21.1015 20.8749 23 20.8749Z"
                        fill="#FCD980"
                      />
                      <path
                        d="M26.3868 23.9615C25.0337 22.8528 23.8083 22.9996 22.2437 22.9996C19.9037 22.9996 18 24.8921 18 27.2177V34.0432C18 35.0532 18.8244 35.8745 19.8381 35.8745C24.2147 35.8745 23.6874 35.9537 23.6874 35.6857C23.6874 30.8491 23.1146 27.3022 26.3868 23.9615V23.9615Z"
                        fill="#FCD980"
                      />
                      <path
                        d="M35.4882 23.0249C32.7555 22.797 30.3802 23.0275 28.3314 24.7187C24.9029 27.4649 25.5627 31.1625 25.5627 35.6861C25.5627 36.8829 26.5364 37.8748 27.7514 37.8748C40.9439 37.8748 41.469 38.3003 42.2513 36.5679C42.5078 35.9821 42.4375 36.1682 42.4375 30.5636C42.4375 26.112 38.583 23.0249 35.4882 23.0249V23.0249Z"
                        fill="#FCD980"
                      />
                      <path
                        d="M45.7564 23.0004C44.1833 23.0004 42.9646 22.8552 41.6133 23.9623C44.8611 27.2783 44.3126 30.5832 44.3126 35.6865C44.3126 35.9562 43.8749 35.8753 48.0963 35.8753C49.1463 35.8753 50.0001 35.0247 50.0001 33.9791V27.2185C50.0001 24.8929 48.0963 23.0004 45.7564 23.0004Z"
                        fill="#FCD980"
                      />
                    </svg>
                  </div>
                  <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                    People Management
                  </p>
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  We believe in creating respectful and safe workplaces. Our
                  approach is to show managers how to build effective
                  Relationships which enables mutual Respect.
                </dd>
                <p
                  to="/should-i-keep-my-employee-s-ihi-number/"
                  className="text-e-blue inline-flex items-center mt-3 hover:underline cursor-pointer relative z-10"
                >
                  Read More
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </p>
              </Link>
              <Link
                to="/risk-and-compliance"
                className="transition cursor-pointer hover:bg-gray-100 p-4"
              >
                <dt>
                  <div className="flex items-center justify-center h-12 w-12 text-white">
                    <svg
                      width="60"
                      height="60"
                      viewBox="0 0 60 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="60" height="60" rx="8" fill="#1D71B8" />
                      <mask
                        id="mask0_1875_1919"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="60"
                        height="60"
                      >
                        <rect width="60" height="60" rx="8" fill="#5C5E84" />
                      </mask>
                      <g mask="url(#mask0_1875_1919)">
                        <rect
                          x="-31.2002"
                          y="32.3999"
                          width="60"
                          height="60"
                          rx="8"
                          fill="#FCD980"
                        />
                        <rect
                          x="9.6001"
                          y="-10.8"
                          width="60"
                          height="60"
                          rx="8"
                          fill="#083974"
                        />
                      </g>
                      <path
                        d="M53.2938 30.3579L48.5044 26.4149L46.8038 28.4805C47.8865 29.3717 49.9931 31.1057 51.5935 32.4233L53.2938 30.3579Z"
                        fill="#FCD980"
                      />
                      <path
                        d="M55.5626 33.9557C55.7744 33.7 55.8752 33.3438 55.8399 32.9799C55.8046 32.6161 55.6374 32.2859 55.3796 32.0743L54.3228 31.2044L52.6226 33.2696C53.2012 33.7459 53.6011 34.0751 53.6795 34.1397C54.2483 34.6072 55.0931 34.5253 55.5626 33.9557Z"
                        fill="#FCD980"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M44.3147 29.4011C47.1378 29.1275 49.2046 26.617 48.931 23.7939C48.6573 20.9707 46.1468 18.9039 43.3237 19.1776C40.5005 19.4513 38.4338 21.9617 38.7074 24.7849C38.9811 27.608 41.4915 29.6748 44.3147 29.4011ZM44.1397 27.597C45.9665 27.42 47.3038 25.7955 47.1267 23.9688C46.9496 22.1421 45.3252 20.8047 43.4985 20.9818C41.6717 21.1589 40.3344 22.7833 40.5115 24.61C40.6885 26.4368 42.313 27.7741 44.1397 27.597Z"
                        fill="#FCD980"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M28.6628 11C30.2024 8.33334 34.0514 8.33333 35.591 11L39.255 17.3463C37.4692 18.891 36.3393 21.1735 36.3393 23.72C36.3393 28.3724 40.1109 32.144 44.7633 32.144C45.7325 32.144 46.6635 31.9803 47.5301 31.6791L47.7118 31.994C49.2514 34.6607 47.327 37.994 44.2478 37.994H20.006C16.9268 37.994 15.0023 34.6607 16.5419 31.994L28.6628 11ZM34.0312 16.348L33.6152 28.906H30.5472L30.1052 16.348H34.0312ZM32.1592 34.99C31.5005 34.99 30.9545 34.7907 30.5212 34.392C30.1052 33.976 29.8972 33.4647 29.8972 32.858C29.8972 32.2513 30.1052 31.7487 30.5212 31.35C30.9545 30.934 31.5005 30.726 32.1592 30.726C32.8005 30.726 33.3292 30.934 33.7452 31.35C34.1612 31.7487 34.3692 32.2513 34.3692 32.858C34.3692 33.4647 34.1612 33.976 33.7452 34.392C33.3292 34.7907 32.8005 34.99 32.1592 34.99Z"
                        fill="#FCD980"
                      />
                    </svg>
                  </div>
                  <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                    Risk and Compliance
                  </p>
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  We keep organisations legally compliant by providing user
                  friendly documents reviewed annually to ensure continuance
                  compliance as well as best practice.
                </dd>
                <p
                  to="/should-i-keep-my-employee-s-ihi-number/"
                  className="text-e-blue inline-flex items-center mt-3 hover:underline cursor-pointer relative z-10"
                >
                  Read More
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </p>
              </Link>
              <Link
                to="/dispute-resolution"
                className="transition cursor-pointer hover:bg-gray-100 p-4"
              >
                <dt>
                  <div className="flex items-center justify-center h-12 w-12 text-white">
                    <svg
                      width="60"
                      height="60"
                      viewBox="0 0 60 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="60" height="60" rx="8" fill="#1D71B8" />
                      <mask
                        id="mask0_1875_1947"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="60"
                        height="60"
                      >
                        <rect width="60" height="60" rx="8" fill="#5C5E84" />
                      </mask>
                      <g mask="url(#mask0_1875_1947)">
                        <rect
                          x="-31.2002"
                          y="32.3999"
                          width="60"
                          height="60"
                          rx="8"
                          fill="#FCD980"
                        />
                        <path
                          d="M9.6001 5.19995C9.6001 -2.34252 9.6001 -6.11376 11.9432 -8.4569C14.2864 -10.8 18.0576 -10.8 25.6001 -10.8H53.6001C61.1426 -10.8 64.9138 -10.8 67.257 -8.4569C69.6001 -6.11376 69.6001 -2.34252 69.6001 5.19995V33.2C69.6001 40.7424 69.6001 44.5137 67.257 46.8568C64.9138 49.2 61.1426 49.2 53.6001 49.2H25.6001C18.0576 49.2 14.2864 49.2 11.9432 46.8568C9.6001 44.5137 9.6001 40.7424 9.6001 33.2V5.19995Z"
                          fill="#083974"
                        />
                      </g>
                      <path
                        d="M34 9C25.2119 9 18 16.2119 18 25C18 33.7881 25.2119 41 34 41C42.7881 41 50 33.7881 50 25C50 16.2119 42.7881 9 34 9ZM32.0637 32.2775L25.0571 25.271L27.7085 22.6196L32.1864 27.0975L41.1759 18.9255L43.6991 21.6996L32.0637 32.2775Z"
                        fill="#FCD980"
                      />
                    </svg>
                  </div>
                  <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                    Dispute Resolution
                  </p>
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Because most claims arise out of managing people, we ensure
                  that you ‘build the case’ legally so your processes are not
                  derailed.
                </dd>
                <p
                  to="/should-i-keep-my-employee-s-ihi-number/"
                  className="text-e-blue inline-flex items-center mt-3 hover:underline cursor-pointer relative z-10"
                >
                  Read More
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </p>
              </Link>
              <Link
                to="/training-and-toolkit"
                className="transition cursor-pointer hover:bg-gray-100 p-4"
              >
                <dt>
                  <div className="flex items-center justify-center h-12 w-12 text-white">
                    <svg
                      width="60"
                      height="60"
                      viewBox="0 0 60 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="60" height="60" rx="8" fill="#1D71B8" />
                      <mask
                        id="mask0_1875_1956"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="60"
                        height="60"
                      >
                        <rect width="60" height="60" rx="8" fill="#5C5E84" />
                      </mask>
                      <g mask="url(#mask0_1875_1956)">
                        <rect
                          x="-31.2002"
                          y="32.3999"
                          width="60"
                          height="60"
                          rx="8"
                          fill="#FCD980"
                        />
                        <rect
                          x="9.59961"
                          y="-10.8"
                          width="60"
                          height="60"
                          rx="8"
                          fill="#083974"
                        />
                      </g>
                      <g clipPath="url(#clip0_1875_1956)">
                        <path
                          d="M20.4153 17.045L27.0445 10.4159L25.9395 9.31089C24.1118 7.48324 21.1379 7.48324 19.3105 9.31113C18.425 10.1964 17.9375 11.3734 17.9375 12.6256C17.9375 13.8775 18.425 15.0548 19.3105 15.94L20.4153 17.045Z"
                          fill="#FCD980"
                        />
                        <path
                          d="M21.7412 18.3704L24.3928 15.7188L28.1924 19.5183L25.5408 22.1699L21.7412 18.3704Z"
                          fill="#FCD980"
                        />
                        <path
                          d="M25.7188 14.3926L28.3704 11.741L32.1699 15.5403L29.5183 18.1919L25.7188 14.3926Z"
                          fill="#FCD980"
                        />
                        <path
                          d="M40.8247 36.1272C40.8247 36.1275 40.8247 36.1275 40.8245 36.1275L40.3877 36.5645L48.6526 39.8704C48.7654 39.9155 48.8833 39.9375 49.0005 39.9375C49.2446 39.9375 49.4844 39.8421 49.6636 39.6629C49.9287 39.3977 50.0103 39 49.8711 38.6516L46.5652 30.3867L40.8247 36.1272Z"
                          fill="#FCD980"
                        />
                        <path
                          d="M35.7051 32.3337L38.3567 29.6821L41.4866 32.8123L38.835 35.4639L35.7051 32.3337Z"
                          fill="#FCD980"
                        />
                        <path
                          d="M39.6826 28.3572L42.3342 25.7053L45.4644 28.8355L42.8128 31.4871L39.6826 28.3572Z"
                          fill="#FCD980"
                        />
                        <path
                          d="M26.0513 33.1497C25.6851 33.5157 25.6851 34.1094 26.0513 34.4754L28.482 36.9061L25.7251 39.6629C25.3591 40.0291 24.7654 40.0291 24.3994 39.6629L18.2122 33.4756C17.8459 33.1097 17.8459 32.5159 18.2122 32.15L42.15 8.21216C42.5159 7.84595 43.1097 7.84595 43.4756 8.21216L49.6629 14.3992C49.846 14.5823 49.9376 14.8223 49.9376 15.0623C49.9376 15.302 49.846 15.542 49.6629 15.7251L46.9061 18.482L43.5916 15.1673C43.2254 14.801 42.6319 14.801 42.2657 15.1673C41.8997 15.5335 41.8997 16.127 42.2657 16.4932L45.5801 19.8076L43.4864 21.9016L41.0557 19.4707C40.6897 19.1048 40.096 19.1048 39.7298 19.4707C39.3638 19.8369 39.3638 20.4304 39.7298 20.7967L42.1604 23.2273L40.0667 25.3213L36.7522 22.0066C36.386 21.6407 35.7925 21.6407 35.4263 22.0066C35.0603 22.3728 35.0603 22.9663 35.4263 23.3326L38.7408 26.647L36.647 28.741L34.2163 26.3103C33.8501 25.9441 33.2566 25.9441 32.8904 26.3103C32.5244 26.6763 32.5244 27.2698 32.8904 27.636L35.3211 30.0667L33.2273 32.1607L29.9129 28.846C29.5467 28.48 28.9531 28.48 28.5869 28.846C28.221 29.2122 28.221 29.8057 28.5869 30.1719L31.9014 33.4864L29.8076 35.5804L27.377 33.1497C27.011 32.7835 26.4173 32.7835 26.0513 33.1497V33.1497Z"
                          fill="#FCD980"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1875_1956">
                          <rect
                            width="32"
                            height="32"
                            fill="white"
                            transform="translate(18 8)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                    Training and Toolkit
                  </p>
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  Our training is specifically tailored to your organisation’s
                  needs. It is designed to become a genuine ‘management habit’
                  so real internal skills are gained.
                </dd>
                <p
                  to="/should-i-keep-my-employee-s-ihi-number/"
                  className="text-e-blue inline-flex items-center mt-3 hover:underline cursor-pointer relative z-10"
                >
                  Read More
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </p>
              </Link>
              {/* {features.map((feature) => (
            <Link to= "/people-management" className='transition cursor-pointer hover:bg-gray-100 p-4' key={feature.name}>
              <dt>
                <div className="flex items-center justify-center h-12 w-12 bg-e-blue text-white">
                  <feature.icon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
              </dt>
              <dd className="mt-2 text-base text-gray-500">{feature.description}</dd>
            </Link>
          ))} */}
            </dl>
          </div>
        </div>
      </div>

      <div className="py-8 bg-gray-50 overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
          <div className="text-base max-w-prose mx-auto lg:max-w-none">
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-500 sm:text-4xl">
              {`About ${service.title}`}
            </p>
          </div>
          <div className="relative z-10 text-base text-gray-500 max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
            <ContentfulRichTech richText={service.content} />
          </div>
          <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
            <div className="relative z-10">
              <div className="prose prose-indigo text-gray-500 mx-auto lg:max-w-none"></div>
            </div>
            <div className="mt-12 relative text-base max-w-prose mx-auto lg:mt-0 lg:max-w-none">
              <svg
                className="hidden lg:block absolute top-0 right-0 -mt-96 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-[500px] xl:-mr-20"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden xl:block bg-gray-50 flow-root pt-12 pb-24">
        <p className="max-w-7xl mx-auto mt-2 pb-4 px-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-500 sm:text-4xl">
          Why Choose Us?
        </p>
        <p className="max-w-7xl mx-auto mt-2 pb-12 px-8 text-xl leading-8 font-medium tracking-tight text-gray-500">
          We value Self-Awareness, Being Helpful and to Own It in working with
          each other and our clients
        </p>
        <ul className="flex justify-between max-w-7xl mx-auto px-8">
          {timeline.map((event, eventIdx) => (
            <li key={event.id}>
              <div className="relative pb-8">
                {eventIdx !== timeline.length - 1 ? (
                  <span
                    className="absolute top-4 border-t border-e-blue border-dashed w-[450px]"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex space-x-3 flex-col">
                  <div>
                    <span
                      className={classNames(
                        event.iconBackground,
                        "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                      )}
                    >
                      <event.icon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                    <div>
                      <h2 className="text-lg text-gray-500 font-semibold">
                        {event.target}
                      </h2>
                      <p className="text-sm text-gray-500 max-w-[325px]">
                        {event.content}
                      </p>
                    </div>
                    <div className="text-right text-sm whitespace-nowrap text-gray-500"></div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <Newsletter />
    </Layout>
  );
};

export default SingleService;
