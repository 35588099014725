import React from "react";
import Layout from "../components/Layout";
import SingleService from "../templates/SingleService";

import { graphql } from "gatsby";

const Services = ({ data }) => {
  const service = data.contentfulService;
  return <SingleService service={service} />;
};

export const query = graphql`
  query getSingleService($title: String) {
    contentfulService(title: { eq: $title }) {
      id
      title
      shortDescription {
        shortDescription
      }
      image {
        gatsbyImageData(placeholder: BLURRED)
      }
      content {
        raw
        # references {
        #   ... on ContentfulAsset {
        #     gatsbyImageData(width: 1000, placeholder: TRACED_SVG)
        #     description
        #     contentful_id
        #     __typename
        #     title
        #     file {
        #       url
        #     }
        #   }
        # }
      }
      summary {
        raw
        # references {
        #   ... on ContentfulAsset {
        #     gatsbyImageData(width: 1000, placeholder: TRACED_SVG)
        #     description
        #     contentful_id
        #     __typename
        #     title
        #     file {
        #       url
        #     }
        #   }
        # }
      }
    }
  }
`;
export default Services;
